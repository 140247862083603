:root{
    /*        BLUES         */
    --bg-darknavy: #1d3660;
    --bg-navy: #01498e;
    --bg-ocean: #005aa6;
    --bg-lightnavy: #006eb7;
    /*      GREEN WATER     */
    --bg-darkwater: #004650;
    --bg-oceanwater: #006881;
    --bg-lightwater: #007fa4;
    --bg-greenwater: #00a3cc;
    /*      GREENS          */
    --bg-darkturquoise: #004F51;
    --bg-turmaline: #00625d;
    --bg-darkaquamarine: #007574;
    --bg-aquamarine: #009490;
    /*      GRAY-GREEN      */
    --bg-darkgraygreen: #00594E;
    --bg-graygreen: #5e7361;
    --bg-greeny: #7e9c92;
    /*      GREENS      */
    --bg-darkgreen: #00833f;
    --bg-green: #009945;
    --bg-grass: #44ac34;
    --bg-lightgrass: #6fb74d;
    /*      GRAYS        */
    --bg-darkgray: #4e5859;
    --bg-gray: #6f7c7c;
    --bg-medgray: #8c8f90;
    --bg-lightgray: #9eabaa;
    /*      BROWNIES     */
    --bg-darkmidbrown: #847770;
    --bg-midbrown: #958B82;
    --bg-lightmidbrown: #aca198;
    --bg-lighterbrown: #bfb7af;
    /*      BROWNERED     */
    --bg-darkbrown: #592d2c;
    --bg-brown: #862d2d;
    --bg-red: #b22029;
    --bg-brownerenge: #ca3725;
    /*      PINKIREDS         */
    --bg-darkpinkyred: #701d46;
    --bg-pinkyred: #802346;
    --bg-purple: #851C66;
    --bg-fuccia: #af1065;
    /*      ORANGES           */
    --bg-darkorange: #E9511D;
    --bg-orange: #ED6C05;
    --bg-lightorange: #F59A29;
    --bg-lightestorange: #FAB528;
    /*      YELLOWRON         */
    --bg-cadmiayellow: #795136;
    --bg-yellow: #cd7a21;
    --bg-brownyellow: #d19106;
    --bg-rareyellow: #fab500;
  }

.col-white{
    color: #fff !important;
}
/*        BLUES         */
.bg-darknavy{
    background-color: var(--bg-darknavy) !important;
}
.bg-navy{
    background-color: var(--bg-navy) !important;
}
.bg-ocean{
    background-color: var(--bg-ocean) !important;
}
.bg-lightnavy{
    background-color: var(--bg-lightnavy) !important;
}
/*      GREEN WATER     */
.bg-darkwater{
    background-color: var(--bg-darkwater) !important;
}
.bg-oceanwater{
    background-color: var(--bg-oceanwater) !important;
}
.bg-lightwater{
    background-color: var(--bg-lightwater) !important;
}
.bg-greenwater{
    background-color: var(--bg-greenwater) !important;
}
/*      GREENS          */
.bg-darkturquoise{
    background-color: var(--bg-darkturquoise) !important;
}
.bg-turmaline{
    background-color: var(--bg-turmaline) !important;
}
.bg-darkaquamarine{
    background-color: var(--bg-darkaquamarine) !important;
}
.bg-aquamarine{
    background-color: var(--bg-aquamarine) !important;
}
/*      GRAY-GREEN      */
.bg-darkgraygreen{
    background-color: var(--bg-darkgraygreen) !important;
}
.bg-graygreen{
    background-color: var(--bg-graygreen) !important;
}
.bg-greeny{
    background-color: var(--bg-greeny) !important;
}
/*      GREENS      */
.bg-darkgreen{
    background-color: var(--bg-darkgreen) !important;
}
.bg-green{
    background-color: var(--bg-green) !important;
}
.bg-grass{
    background-color: var(--bg-grass) !important;
}
.bg-lightgrass{
    background-color: var(--bg-lightgrass) !important;
}
/*      GRAYS        */
.bg-darkgray{
    background-color: var(--bg-darkgray) !important;
}
.bg-gray{
    background-color: var(--bg-gray) !important;
}
.bg-medgray{
    background-color: var(--bg-medgray) !important;
}
.bg-lightgray{
    background-color: var(--bg-lightgray) !important;
}
/*      BROWNIES     */
.bg-darkmidbrown{
    background-color: var(--bg-darkmidbrown) !important;
}
.bg-midbrown{
    background-color: var(--bg-midbrown) !important;
}
.bg-lightmidbrown{
    background-color: var(--bg-lightmidbrown) !important;
}
.bg-lighterbrown{
    background-color: var(--bg-lighterbrown) !important;
}
/*      BROWNERED     */
.bg-darkbrown{
    background-color: var(--bg-darkbrown) !important;
}
.bg-brown{
    background-color: var(--bg-brown) !important;
}
.bg-red{
    background-color: var(--bg-red) !important;
}
.bg-brownerenge{
    background-color: var(--bg-brownerenge) !important;
}
/*      PINKIREDS         */
.bg-darkpinkyred{
    background-color: var(--bg-darkpinkyred) !important;
}
.bg-pinkyred{
    background-color: var(--bg-pinkyred) !important;
}
.bg-purple{
    background-color: var(--bg-purple) !important;
}
.bg-fuccia{
    background-color: var(--bg-fuccia) !important;
}
/*      ORANGES           */
.bg-darkorange{
    background-color: var(--bg-darkorange) !important;
}
.bg-orange{
    background-color: var(--bg-orange) !important;
}
.bg-lightorange{
    background-color: var(--bg-lightorange) !important;
}
.bg-lightestorange{
    background-color: var(--bg-lightestorange) !important;
}
/*      YELLOWRON         */
.bg-cadmiayellow{
    background-color: var(--bg-cadmiayellow) !important;
}
.bg-yellow{
    background-color: var(--bg-yellow) !important;
}
.bg-brownyellow{
    background-color: var(--bg-brownyellow) !important;
}
.bg-rareyellow{
    background-color: var(--bg-rareyellow) !important;
}