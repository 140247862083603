.form {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .form input {
    width: 90%;
    height: 30px;
    margin: 0.5rem;
  }
  
  .form button {
    padding: 0.5em 1em;
    border: none;
    background: rgb(100, 200, 255);
    cursor: pointer;
  }
  .h2-form{
    text-align:  center;
    margin-top: 10%;
  }