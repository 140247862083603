.card-form-dashboard{
    border-radius: .15rem;
    position: relative;
    padding: 0;
    background: #1d3660;
}
.card-form-dashboard:before{    
    content: "";
    font-size: large;
    color: #fff;
    text-align: center;
    display: block;
    width: 100%;
    margin: 0;
    padding: .25rem;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    background: linear-gradient(to left, #006eb7 35%, #1d3660);
}
.card-form-dashboard-title{
    background: #1d3660;
    font-size: 1.3rem;
    margin-top: .25rem;
    padding: .5rem 2rem;
    font-weight: bold;
    color: #41d9ff;
}
