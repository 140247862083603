

.calendar__event{
  background-color: #005AA6;
  white-space: pre-wrap;
  width: 100%;
  padding: 1em;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
  color: white;
  display: flex;
  flex-direction: column-reverse;
  cursor: pointer;
}
.calendar__event b{
  margin-right: 6px;
  background-color: white;
    border-radius: 0.2rem;
    color: #005AA6;
    padding: 3px;
    font-weight: 500;
}
.calendar__event i{
  word-wrap: break-word;
  font-weight: 600;
  width: 100%;
}
.lista-respuesta{
  margin-bottom: 10px;
  list-style: circle;
}