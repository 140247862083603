@import './palette/halconPanetone.css';

* {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: 'Poppins', 'Roboto';
  list-style: none;
  width: 100%;
  height: 100%;
  position: relative;
}
table thead{
  background:#005aa6;
  color: #fff;
}
table th{
  height: 50px;
}
.table>:not(:first-child) {
  border-top: 1px solid currentColor;
}
.pagination{
  display: inline-flex;
}
.btn-custom {
  cursor: pointer;
  user-select: none;
}
.nav-menu-checkbox {
  display: none;
}
a {
  text-decoration: none;
  user-select: none;
}
code {
  font-family: 'Nunito Sans', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 5px;
  transition: ease-out .1s;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #00a3cc;
}

.card.width100 {
  width: 100% !important;
  height: 100% !important;
}



/* //override bootstrap menu item */
 .accordion-item.menu-side {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0) !important;
}
.fc-toolbar-title {
  font-size: 1.75em;
  margin: 0;
  text-transform: lowercase;
}
.fc-toolbar-title:first-letter {
  margin: 0;
  text-transform: uppercase;
}