.inicio__header {
  position: sticky;
  width: 100%;
  top: 0;
  z-index: 4;
  background-color: #fff;
}

.inicio__container {
  width: 100vw;
}

.inicio__welcome {
  /* padding-top: 4rem; */
  padding-bottom: 8rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 0 auto;
  max-width: 1400px;
  /* max-height: 95vh; */
  overflow-y: scroll;
}

.inicio__welcome::-webkit-scrollbar {
  display: none;
}

.inicio__column-description {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  padding: 0 2rem;
  position: sticky;
  top: 4rem;
}

.inicio__title {
  font-size: 2.5rem;
  font-weight: 700;
  color: #1f1f1f;
  text-align: center;
  margin-bottom: 3rem;
  line-height: 1.2;
}

.inicio__description {
  font-size: .7rem;
  color: #4e5859;
  text-align: center;
  line-height: 1.5;
}

.inicio__button {
  padding: 0.5rem 2rem;
  font-weight: 700;
  font-size: 1.2rem;
  background-color: #013E7A;
  color: #fff;
  border-radius: 0.4rem;
  margin-top: 3rem;
  text-align: center;
}

.inicio__button:hover {
  background-color: #005AA6;
  color: #fff;
}

.inicio__column-notice {
  /* display: none; */
  margin-top: 5rem;
  width: 80%;
}

.inicio__footer {
  display: flex;
  width: 100vw;
  justify-content: center;
  align-items: center;
  background-color: #013E7A;
}

.inicio__network {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  margin-top: 2rem;
  flex-wrap: wrap;
}

@media (min-width: 768px) {

  .inicio__welcome {
    padding-top: 1rem;
    padding-bottom: 4rem;
  }

  .inicio__network {
    display: none;
    margin-top: 2rem;
  }

  .inicio__column-description {
    width: 60%;
    align-items: flex-start;
  }

  .inicio__title {
    font-size: 2.2rem;
    margin-bottom: 1.2rem;
    text-align: left;
  }

  .inicio__description {
    font-size: .9rem;
    text-align: left;
    line-height: 1.3;
  }

  .inicio__column-notice {
    display: flex;
    width: 40%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 2rem;
  }
}

@media (min-width: 1024px) {

  .inicio__column-description {
    width: 55%;
  }

  .inicio__title {
    font-size: 3rem;
  }

  .inicio__description {
    font-size: 1.2rem;
  }

  .inicio__column-description {
    width: 50%;
    align-items: flex-start;
    padding: 0 3rem;
  }
}

@media (min-width: 1920px) {
  .inicio__welcome {
    padding-top: 4rem;
    padding-bottom: 8rem;
    align-items: center;
  }
}

.tabs {
  position: relative !important;
  height: inherit;
  background-color: inherit !important;
  display: flex !important;
  flex-direction: row !important;
  height: auto !important;
  z-index: 1 !important;
}

.button_registrarse {
  color: #013E7A !important;
  border-color: #013E7A !important;
  font-weight: 700;
  font-size: 1.2rem;
  padding: .5rem 2rem;
  border-radius: 10px;
  margin-top: 1rem;
}

.button_registrarse:hover {
  background-color: #013E7A !important;
  color: #fff !important;
}
.informacion-ingreso{
  width: 100%;
  text-align: center;
  display: block;
}
.informacion-ingreso p{
  display: inline-block;
  background: #e3e37a;
  width: auto;
  margin: 0 auto;
  padding: 10px;
  margin-bottom: 30px;
}