.recover-password {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 85vh;
}

.recover-password__card {
    /* background-color: #44ac34; */
    /* color: #fff; */
    padding: 3rem;
}

.recover-password__success {
    color: #009945;
    font-weight: 600;
}