.ReceiptUploaded__receipt-of-payment-message {
    margin-top: 2rem;
    margin-bottom: 0;
}

.ReceiptUploaded__voucher-information {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ReceiptUploaded__creation-date>p:nth-child(1) {
    color: #4e5859;
    margin-bottom: 0;
}

.ReceiptUploaded__creation-date>p:nth-child(2) {
    margin-bottom: 0;
}

.texto_ver_doc {
    margin-top: -10px;
}