.trimester__container {
    display: grid;
    margin-top: 2rem;
    grid-template-columns: 100%;
    grid-template-rows: auto auto;
}

.trimester__card {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.trimester__bar {
    margin: 0.5rem;
}

.trimester__doughnut {
    margin: 0.5rem;
}

.trimester__buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

@media (min-width: 768px) {
    .trimester__container {
        grid-template-columns: 60% 40%;
        grid-template-rows: auto auto;
    }
}