.dashboard {
    height: calc(100vh - 64px);
}

.dashboard__main {
    display: flex;
    width: 100%;
    height: 100%;
}

.dashboard__nav {
    display: none;
}

.dashboard__container {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
}

@media (min-width: 768px) {
    .dashboard__nav {
        display: block;
        width: 20vw;
        height: 100%;
        overflow-y: scroll;
    }

    .dashboard__container {
        width: 80vw;
        height: 100%;
        overflow-y: scroll;
    }
}