.contenedorGlobalCarrousel{
    margin-top: 0px;
}

@media (min-width: 239px) {
    .contenedorGlobalCarrousel{
        margin-top: -100px;
    }
}

@media (min-width: 374px) {
    .contenedorGlobalCarrousel{
        margin-top: -80px;
    }
}

@media (min-width: 410px) {
    .contenedorGlobalCarrousel{
        margin-top: -90px;
    }
}


@media (min-width: 768px) {
    .contenedorGlobalCarrousel{
        margin-top: 20px;
    }
}


@media (min-width: 1024px) {
    .contenedorGlobalCarrousel{
        margin-top: 80px;
    }
}
    

@media (min-width: 1280px) {
    .contenedorGlobalCarrousel{
        margin-top: -80px;
    }
   
}

.separador{
    width: 100%;
    height: 50px;
}

@media (min-width: 768px) {
    .separador{
        width: 100%;
        height: 150px;
        margin-top: -100px;
    }
}

@media (min-width: 1280px) {
    .separador{
        width: 100%;
        height: 200px;
        margin-top: 0px;
    }

    
}
.inicio__carousel{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 2rem;
    padding-bottom: 2rem;
    background-color: #1d3660;
}

.titulo_carousell{
    width: 100%;
    background: linear-gradient(to left, #006eb7 35%, #1d3660);
    padding: .4rem 0rem;
}


.carrousell_container{
    max-width: 1200px;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.borderImg{
    border-radius: 10px;
}

.shadow{
    -webkit-box-shadow: 4px 5px 12px 2px rgba(0,0,0,0.78); 
    box-shadow: 4px 5px 12px 2px rgba(0,0,0,0.78);
}