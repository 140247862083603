.search-container {
  display: flex;
  justify-content: end;
}
.search-container  .input-group  {
width: 30% ;
}
.row-student{
  padding-top: 3rem;
  padding-bottom: 3rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  border-radius: 0.5rem;
  margin-top: 1rem;
}
.content-student{
  margin-top: 1rem;
  display: flex;
  padding: 0.3rem 0rem;
  align-items: center;
}
table {
  width: 100%;
}
table .thead-student{
  margin-bottom: 1rem;
  background-color: #005AA6;
  color: white;
}
.applicant-list__img-user.student {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  margin-right: 0.1rem;
}
.details:hover{
cursor: pointer;
}
/* //override */
.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
  
  background-color: #005AA6;
}
.evento-date-container{
  border-radius: 0.5rem;
  cursor: pointer;
  width: 33.3%;
  box-shadow: rgb(50 50 93 / 25%) 0px 6px 12px -2px, rgb(0 0 0 / 30%) 0px 3px 7px -3px;
  padding: 5px;
  margin: 0px;
}
.evento-date-container__evento-asistencias{
  justify-content: center;
  padding: 0.3rem 0.5rem;
  /* margin-right: 0.3rem;
  width: 100pt; */
  /* padding: 0.3rem; */
  
  
}
.evento-date-container__evento-header{
  background-color: #FD9F1B;
  height: 2rem;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  color: white;
  justify-content: center;
  display: flex;
  padding: 0.5rem 0rem;
}
.evento-container{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}


/* nota buble */
.notas-container{
  display: flex;
  margin-top: 2rem;
  justify-content: center;
}

.nota-item{
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: var(--bg-ocean);
  color: aliceblue;
  padding: 2.4rem 2.2rem;
  margin-bottom: 0.2rem;
  margin-right: 10px;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}
.porcentaje-nota{
position: absolute;
float: right;
background-color: aliceblue;
color:var(--bg-ocean) ;
right: 0.3rem;
border-radius: 50%;
padding: 0.4rem 0.2rem;
bottom: 0.3rem;
box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
}