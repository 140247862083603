.arrow--sticky {
    top: 0;
}

.arrow__button {
    /* position: sticky; */
    top: 0;
    font-size: 2rem;
}

.arrow.white {
    color: #fff;
}