.enrollment__container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.enrollment__upload-file {
    display: flex;
    align-items: flex-end;
    width: 80%;
    margin-top: 0;
}

.enrollment__button-enroll {
    display: flex;
    justify-content: flex-end;
    margin-top: 2rem;
}

.enrollment__receipt-of-payment {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.enrollment__receipt-of-payment-message {
    margin-top: 2rem;
    margin-bottom: 0;
}

.enrollment__voucher-information {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.enrollment__creation-date > p:nth-child(1) { 
    color: #4e5859;
    margin-bottom: 0;
}

.enrollment__creation-date > p:nth-child(2) {
    margin-bottom: 0;
}