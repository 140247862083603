.form-sign-up__container {
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
}

.form-sign-up__columnTwo {
    width: 100%;
    padding: 2rem;
}

.form-sign-up__columnOne {
    width: 100%;
    max-width: 1200px;
    padding: 2rem;
}

.form-sign-up__button {
    display: flex;
    justify-content: center;
}

@media (min-width: 768px) {
    
    .form-sign-up__container {
        display: flex;
    }

    .form-sign-up__columnOne {
        width: 50%;
        padding: 2rem 2rem 2rem 4rem;
    }

    .form-sign-up__columnTwo {
        width: 50%;
        padding: 2rem 4rem 2rem 2rem;
    }
}

@media (min-width: 1024px) {

    .form-sign-up__columnOne {
        padding: 2rem 2rem 2rem 20rem;
    }

    .form-sign-up__columnTwo {
        padding: 2rem 20rem 2rem 2rem;
    }
    
}

@media (min-width: 1280px) {

    .form-sign-up__columnOne {
        padding: 2rem 2rem 2rem 20rem;
    }

    .form-sign-up__columnTwo {
        padding: 2rem 20rem 2rem 2rem;
    }
    
}

.form-update__container {
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
}

.form-update__columnTwo {
    width: 100%;
    padding: 2rem;
}

.form-update__columnOne {
    width: 100%;
    max-width: 1200px;
    padding: 2rem;
}

.form-update__button {
    display: flex;
    justify-content: center;
}

@media (min-width: 768px) {
    
    .form-update__container {
        display: flex;
    }

    .form-update__columnOne {
        width: 50%;
        padding: 2rem 2rem 2rem 4rem;
    }

    .form-update__columnTwo {
        width: 50%;
        padding: 2rem 4rem 2rem 2rem;
    }
}

@media (min-width: 1024px) {

    .form-update__columnOne {
        width: 50%;
        padding: 1rem 1rem 1rem 10rem;
    }

    .form-update__columnTwo {
        width: 50%;
        padding: 1rem 10rem 1rem 1rem;
    }
    
}

@media (min-width: 1280px) {

    .form-update__columnOne {
        width: 50%;
        padding: 1rem 1rem 1rem 10rem;
    }

    .form-update__columnTwo {
        width: 50%;
        padding: 1rem 10rem 1rem 1rem;
    }
    
} 
 


.borderx{
    border-top: 1px solid #000 !important;
}

.text-habeas{
    font-size: 0.8rem;
    color: #000;
    text-align: center;
    margin-bottom: 1rem;
    max-width: 1200px;
    margin: 0 auto;
}

.showPass{
    border: 1px solid rgb(175, 175, 175);
    margin-top: 50px;
    border-radius: 5px;
}