#inicio-bg{
  position: absolute;
  height: 80%;
  width: 100vw;
  z-index: -1;
  padding-top: 7rem;
  background-color: #002C5F;
}

.cursos__header {
  background-color: #002C5F;
}

.nav{
  width: 100%;
  top: 0;
}
/* .card-curso{
  display: flex;
  overflow: hidden;
  position: relative;
  height: 7rem;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 0.5rem;
  padding: 2.5rem;
  color: #fff;
  background-color: #006eb7;
  border: solid 1px #8c8f90;
  cursor: pointer;
  transition: ease-in .1s;
  font-weight: 700;
} */
.items-menu{
  display: flex;
  gap: 1rem;
}
#inicio-logo{
  width: 80px;
}
#portada-image{
  width: 350px;
}
.navbar.inicio{
  padding-left: 3rem;
  padding-right: 3rem;
  background-color: #002C5F !important;
}
.collapse-background{
  background-color: #006eb7;
}
.accordion-body{
  /* background-color: #fff;
  padding: 0 !important; */
  text-align: left;
}
.link-sidebar-menu{
  color: #333;
  width: 100%;
  background-color: #f2f2f2;
  padding: 1rem 2rem;
  display: flex;
  transition: 0.1s ease-in;
  position: relative;
}
.link-sidebar-menu:after{
  content: "";
  width: 0;
  height: 100%;
  background-color: #fff;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 1;
  transition: .25s ease-in;
}
.link-sidebar-menu:hover{
  background-color: #002c5f;
  color: #fff;
  z-index: 2;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 0px 10px;
}
.link-sidebar-menu:hover:after{
  width: 100%;
  opacity: .1;
}
.inicio li{
}
.items-menu li a{
  color: white;
  padding: .3rem .8rem;
  font-size: 18px;
}
.item-menu-activo{
  background-color: var(--bg-ocean);
  color: #fff;
  z-index: 2;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 0px 10px;
}
.accordion-button:not(.collapsed) {
  font-weight: bold;
  color: #002c5f;
}
.btn.inicio{
  background-color: white;
  font-size: 18px;
  color:#012F61;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  border-color: aliceblue;
}
.btn.inicio:hover{
  border-color: aliceblue;
}
.left-content{
  padding-left: 5vw;
  padding-top: 4vh;
}
.right-content{
  padding-left: 6vw;
}
.cards-container{
  display: flex;
  flex-wrap: wrap;
}
.main-text{
  color: white;
}

/* .card.dimensiones{
  margin-right: 1rem;
  margin-bottom: 1rem;
  height: 8rem;
  width: 12rem;
} */

/* curso */
.cursos-container{
  margin-top: 4rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  
}
.cursos-container .card{
  margin-right: 2rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
@media only screen and (max-width: 1050px) {
  #portada-image{
    width: 250px;
  }
}
@media only screen and (max-width: 768px) {
  #portada-image{
    display: none;
  }
  /* .nav{
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: #02060ab6;
    backdrop-filter: blur(4px);
    position: absolute;
    z-index: 5;
    display: none;
  } */
  .burguer-menu{
    display: flex;
    height: 42px;
    width: 42px;
    color: #fff;
    border-radius: 2px;
    background-color: #002C5F;
    position: relative;
  }
  .burguer-menu:after{
    content: "|||";
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
    transform: rotate(90deg);
    font-size: 35px;
    transition: 0.1s ease-in;
  }
  #open-menu:checked ~ #menu-opening{
    display: flex;
  }
  .items-menu{
    width: 50%;
    background-color: #fff;
    display: flex;
    padding-top: 2rem;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    gap: 0;
  }
  #inicio-bg{
    height: 40%;
  }
  .items-menu li a{
    color: rgb(0, 0, 0);
    font-size: 18px;
  }
  .items-menu li{
    background-color: white;
    width: 100%;
    font-size: 18px;
    color:#012F61;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    border-color: aliceblue;
  }
}
@media only screen and (max-width: 568px) {
  #portada-image{
    width: 200px;
  } 
  .right-content{
    display: none;
    padding-left: 0;
  }
  .nav.items-menu{
    background-color: #02060a;
    position: absolute;
  }
  #inicio-bg{
    height: 35%;
  }
}
@media only screen and (max-width: 385px) {
  #portada-image{
    width: 100px;
  } 
  .right-content{
    display: none;
    padding-left: 0;
  }
  #inicio-bg{
    height: 25%;
  }
}


li .inicio{
  transition: ease-out .1s;
}
li .inicio:hover{
  background: linear-gradient(to bottom, #005aa6 10%, #182784);
  color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.5) 0px 5px 6px, rgba(0, 0, 0, 0.12) 0px 3px 8px;
  border: #006eb7 1px solid;
  transform: translateY(-2px);
}
.left-content{
  padding-left: 5vw;
  padding-top: 4vh;
}
.right-content{
  padding-left: 6vw;
}
.cards-container{
  display: flex;
  flex-wrap: wrap;
}
.main-text{
  color: white;
}
.card.dimensiones{
  margin-right: 1rem;
  margin-bottom: 1rem;
  height: 8rem;
  width: 15rem;
  cursor: pointer;
  transition: .1s ease-in;
  background: linear-gradient(to Bottom,white 90%, rgb(165, 165, 165));
  position: relative;
  overflow: hidden;
  background-color: rgb(255, 255, 255);  
}
.card.dimensiones:before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.12;
  background-image: url('../../../src/assets/logo-aeronautica.png');
  background-position: top;
  background-repeat: no-repeat;
  background-size: 50%;
  z-index: 3;
  opacity: .1;
}
.card.dimensiones *{
  z-index: 3;
  font-weight: bold;
  text-align: center;
  font-size: 12px;
}

.card.dimensiones::after{
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to Top, #1d3660 5%, #01468e 35%, #005aa6 50%, #006eb7);
  opacity: 1;
  z-index: 2;

  transition: 0.1s ease-in;
  transform: translateX(0%);
}
.card.dimensiones:hover{
  transform: translateY(-2%);
  background: linear-gradient(to Bottom,white 75%, rgb(125, 125, 125));
  box-shadow: rgba(0, 0, 0, 0.35) 0px 4px 8px, rgba(0, 0, 0, 0.52) 0px 6px 10px;
}
.card.dimensiones:hover::after{
}
.card.dimensiones:hover::before{
  z-index: 3;
  opacity: .81;
}
.card.dimensiones:hover .titles-container{
  top: 40%;
}
/* .card-body{
  padding: 0 !important;
} */
.titles-container{
  transition: .1s ease-in-out;
  position: absolute;
  top: 25%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  left: 0;
  right: 0;
  padding: 15px;
}
.card.dimensiones h5{
  color: white;
  font-weight: bolder;
}
.card.dimensiones h6{
  font-weight: bolder;
  color: #fab500;
}
.card-title{
  color:#182784;
  font-weight: 500;
}
.menu-opcion{
  border: transparent 1px solid;
  transition: .1s ease-in;
}
.menu-opcion:hover{
  border-radius: 3px;
  color: #fff;
  background: linear-gradient(to bottom, #005aa6 10%, #182784);
  box-shadow: rgba(0, 0, 0, 0.5) 0px 5px 6px, rgba(0, 0, 0, 0.12) 0px 3px 8px;
  border: #006eb7 1px solid;
  transform: translateY(-2px);
}
*{
  box-sizing: border-box;
}
.sticky-fixed{
  bottom: 0;
  position: fixed;
}