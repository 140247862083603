
.evento-container{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}
.evento-date-card__evento-header{
  background-color: #FD9F1B;
  height: 2rem;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  color: white;
  justify-content: end;
  display: flex;
  padding: 0.5rem 0.5rem;
}
.evento-date-card{
  border-radius: 0.5rem;
  
  width: 9rem;
  margin:  1rem 0.4rem;
  height: 6rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.evento-date-card span{
  cursor: pointer;
}
.event-content{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem;
}
