.students-list {
    padding: 0 2rem;
}

.student-list__search {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
}

.students-list__controller {
    display: flex;
    justify-content: flex-end;
}

.students-list__list-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}