.job-offer-card__title {
    color: #006EB7;
    margin: 1rem;
}

.job-offer-card__text {
    margin: 1rem 1rem;
}

.job-offer-card__description {
    margin: 1rem;
}

.job-offer-card__description > p {
    font-size: 0.9rem;
    color: #727272;
    margin: 0.5rem 0;
}
.n-job{
    font-weight: bold;
}