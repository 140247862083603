.profile-header {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.profile-header__img-container {
    margin-right: 1.5rem;
}

.profile-header__avatar {
    width: 10rem;
    height: 10rem;
    border-radius: 50%;
    object-fit: cover;
}

.profile-header__award {
    display: flex;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    margin-top: -2rem;
    margin-left: 5rem;
    object-fit: cover;
}

.profile-header__name {
    font-weight: 600;
}

.profile-header__tag {
    color: #6f7c7c;
}

.profile-header__data-user {
    display: flex;
    flex-wrap: wrap;
}

.profile-header__data {
    padding: 1rem;
}

.profile-header__data > h6 {
    color: #6f7c7c
}

.profile-header__data > h5 {
    color: #4e5859
}

.profile-header__button-dropdown {
    margin: 0 auto;
    padding: 0.2rem 1rem;
    text-align: start;
    width: 100%;
}

.profile-header__button-dropdown:hover {
    background-color: #9eabaa27;
}

@media (min-width: 768px) {

    .profile-header {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .profile-header__info-user {
        display: flex;
    }

    .profile-header__info-user > section {
        padding-top: 1rem;
        padding-right: 1.5rem;
    }
}