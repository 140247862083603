.footer-home {
    background-color: #013e7a;
    padding: 3rem 0 .5rem 0;
    color: #fff;
}

.footer-home.light {
    background: none;
    color: #1d1d1d;
}

.footer-home__container {
    display: grid;
    justify-content: center;
    grid-template-columns: auto;
}

.footer-home__email {
    margin: 1rem 0;
}

.footer-home__email > p {
    margin: 0;
}

.footer-home__email > h5 {
    margin: 0;
}

.footer-home__networks {
    margin: 3rem 0;
    font-size: 3rem;
}

.footer_button {
    color: #fff;
    margin: 0;
    padding: 0 0.8rem;
    font-size: 0.9rem;
}

.footer_button.light {
    color: #1d1d1d;
}

.footer-home__international-aeronautics {
    border-left: solid 3px #fff;
    border-radius: 2px;
    margin: 1rem 0;
}

.footer-home__copyright {
    font-size: 0.8rem;
    text-align: center;
    margin: 2rem 0 1rem;
    padding: 0 2rem;
}

@media (min-width: 768px) {
    .footer-home__container {
        justify-content: inherit;
        grid-template-columns: auto auto auto;
    }
}