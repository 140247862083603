.graduate__controller {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 1.5rem;
}

.graduate__guide {
    font-size: 0.8rem;
    margin-top: 1rem;
    color: #9eabaa;
    font-weight: 300;
}