.view-pdf {
    display: flex;
    width: 14rem;
    height: 14rem;
    margin: 1.5rem;
    border: 1px solid #dfdfdf;
    border-radius: 0.4rem;
    flex-direction: column;
    justify-content: space-around;
}

.view-pdf:hover {
    border: none;
    background-color: #f5f5f5;
}

.view-pdf__button-view-pdf {
    width: 100%;
    margin: 0;
    padding: 0;
    color: #4e5859;
}

.view-pdf__button-view-pdf:hover {
    color: #4e5859;
}

.view-pdf__info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.view-pdf__date-container {
    text-align: justify;
}

.view-pdf__date-title {
    font-size: 0.9rem;
    margin-bottom: 0;
}

.view-pdf__date {
    font-size: 0.8rem;
    margin-bottom: 0;
}

.view-pdf__control {
    width: 100%;
    padding: 0.2rem;
}

.view-pdf__control-buttons-container {
    display: flex;
    justify-content: center;
    align-self: flex-end;
}

.view-pdf__button-decline {
    padding: 0 0.5rem;
    margin: 0;
    color: #b22029;
    font-weight: 500;
}

.view-pdf__button-decline:hover {
    color: #ca3725;
}

.view-pdf__button-accept {
    padding: 0 0.5rem;
    margin: 0;
    font-weight: 500;
    color: #00594e;
}

.view-pdf__button-accept:hover {
    color: #5e7361;
}

.view-pdf__rejected-status {
    border-radius: 0.4rem;
    padding: 0.4rem;
    color: #fff;
    background-color: #ca3825c5;
}

.view-pdf__accepted-status {
    border-radius: 0.4rem;
    padding: 0.4rem;
    color: #fff;
    background-color: #00994594;
}