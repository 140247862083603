.offer-card {
    border-radius: 0.4rem;
    width: 25rem;
    height: auto;
    -webkit-box-shadow: 0px 0px 25px 5px rgba(224, 224, 224, 0.527); 
    box-shadow: 0px 0px 25px 5px rgba(224, 224, 224, 0.527);
    margin: 1rem;
}

.offer-card__img-container {
    width: 25rem;
    height: 14rem;
    border-radius: 0.4rem;
}

.offer-card__img {
    width: 100%;
    height: 100%;
    border-radius: 0.4rem;
    object-fit: cover;
    border: 1px solid #ccc;
}

.offer-card__snap {
    padding: 0.2rem 2rem;
}

.offer-card__program-name {
    text-align: center;
    font-weight: bold;
}

.offer-card__content {
    /* display: flex; */
    margin-top: 1.5rem;
}

.offer-card__description {
    margin-right: 2rem;
    font-size: 0.9rem;
    margin-bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
            line-clamp: 5; 
    -webkit-box-orient: vertical;
}

.offer-card__attributes {
    /* display: grid; */
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 2rem 2rem;
}

.offer-card__attribute-text {
    margin-right: 1rem;
}

.offer-card__button {
    display: flex;
    justify-content: flex-end;
}
.offer-card__attributes span{
    font-weight: bold;
}