.header-table{
  display: flex;
  text-align: right;
  justify-content: flex-end;
}
.refresh-container{
  justify-content: end;
  color: gray;
  font-size: 13px;
  align-items: baseline;
  padding-bottom: 0.2rem;
  margin: 0.2rem 0;
  padding: 5px 15px;
  cursor: pointer;
}

.refresh-container span{
  margin-right: 0.5rem;
}
.refresh-container a{
  color: gray;
}
.refresh-container span:hover{
  cursor: pointer;
}

/* override bs */
.page-item:hover{
  cursor: pointer;
}
.page-item.disabled:hover{
  cursor: not-allowed;
}

.dynamic-table__size-text {
  font-size: 0.8rem;
}
.total-card {
  margin-right: 8rem;
  background-color: #F2F2F2;
  padding: .5rem 1.5rem;
  width: max-content;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  align-content: center;
  height: min-content;

}

@media (min-width: 576px) {
  .dynamic-table__size-text {
    font-size: 1rem;
  }
}
.header-tabla{
  position: 'sticky';
  top: '0';
  boxShadow: 'rgba(0, 0, 0, 0.4) 0px 6px 7px';
  borderBottom: 'transparent 1px solid';
  zIndex: 1;
  background: '#005AA6',;
}