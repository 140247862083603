.login {
  width: 100vw;
  min-height: 100vh;
  padding-bottom: 2rem;
}

.login::before {
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background-image: url('../../../public/img/cea_30-04-2022-1.jpg');
  background-size: cover;
  background-position: center;
  filter: brightness(0.7);
}

.login__header {
  position: relative;
}

.login__main {
  width: 100%;
  margin: 0 auto;
}

.login__form {
  /* padding: 0 4rem; */
}

.login__reset-password {
  margin: 0;
  font-size: 0.9rem;
}

.login__reset-password:hover {
  text-decoration: underline;
}

.login__separador {
  display: flex;
  align-items: center;
  margin: 1rem 0;
}

.login__separador>hr {
  width: 100%;
  border: 1px solid #9eabaa;
  margin: 0.5rem 0;
}

.login__separador>span {
  padding: 0 1rem;
  color: #9eabaa;
}

.login__alternate-options {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 90vw;
  padding: 0 4rem;
  margin: 0 auto;
}

.login__alternative-button {
  font-weight: 600;
  color: #01498E;
  margin: 0;
}

.login__alternative-button:hover {
  color: #006EB7;
}

.login__logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem 0;
}

.login__logo {
  width: 4.5rem;
  height: auto;
  margin: 1rem;
}

.login__change-password {
  display: flex;
  justify-content: center;
}

@media (min-width: 768px) {
  .login__main {
    /*  width: 35rem; */
  }

  .login__form {
    /* padding: 0 7rem; */
  }

  .login__alternate-options {
    width: 35rem;
    padding: 0 7rem;
  }
}

@media (min-width: 1280px) {
  .login__main {
    /*  width: 37rem; */
  }

  .login__form {
    /* padding: 0 6.5rem; */
  }

  .login__alternate-options {
    width: 37rem;
    padding: 0 6.5rem;
  }
}

@media (min-width: 1920px) {
  .login__main {
    /* padding-top: 8rem; */
  }
}