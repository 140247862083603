.offer-header {
    padding-bottom: 2rem;
}

.offer-header__img {
    width: 100%;
    height: 8rem;
    border-radius: 0.4rem;
    object-fit: cover;
    border: 1px solid #ccc;
}

.offer-header__controller {
    display: flex;
    align-items: center;
}

.offer-header__title {
    margin-bottom: 1rem;
}

.offer-header__button {
    margin-left: 0;
}

.offer-header__text-active {
    margin: 0 auto;
    margin-left: 1rem;
    color: #44AC34;
    font-size: 0.8rem;
}

.offer-header__limit-text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
            line-clamp: 3; 
    -webkit-box-orient: vertical;
}

.offer-header__modal {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.offer-header__buttons-to-course {
    margin-top: 2rem
}

@media (min-width: 768px) {

    .offer-header {
        display: flex;
        padding: 2rem 0;
    }

    .offer-header__img-container {
        margin-right: 2rem;
    }

    .offer-header__img {
        width: 25rem;
        height: 15rem;
        border-radius: 0.4rem;
        object-fit: cover;
    }

    .offer-header__title {
        align-items: center;
    }

    .offer-header__text-active {
        font-size: 0.9rem;
    }
    
}