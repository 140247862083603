.notas-container{
  display: flex;
  margin-top: 2rem;
  justify-content: center;
}

.nota-item{
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: var(--bg-ocean);
  color: aliceblue;
  padding: 2.4rem 2.2rem;
  margin-bottom: 0.2rem;
  margin-right: 10px;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}
.porcentaje-nota{
position: absolute;
float: right;
background-color: aliceblue;
color:var(--bg-ocean) ;
right: 0.3rem;
border-radius: 50%;
padding: 0.4rem 0.2rem;
bottom: 0.3rem;
box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
}

.nota-definitiva{
  background: #dbdbe5;
  justify-content: center;
  display: flex;
  padding: 1rem 0rem;
  border-radius: 0.5rem;
}
/* .perdio{
  color: red !important;
} */
.perdio td{
  color: red !important;
}
.boton-tabla{
  text-align: center;
}
.boton-tabla button{
  margin: 0px;
  padding: 0px;
  /* display: block; */
}
.cont-notas{
  padding-bottom: 50px;;
}
.cont-notas td{
  padding: 10px;
}