.navbar-dashboard {
    display: flex;
    justify-content: space-between;
    background-color: #005AA6;
    color: #fff;
    -webkit-box-shadow: 0px 0px 25px 5px rgba(224, 224, 224, 0.527); 
    box-shadow: 0px 0px 25px 5px rgba(224, 224, 224, 0.527);
}
.navbar-dashboard_2 {
    display: flex;
    justify-content: space-between;
    color: #002860;
    background-color: #fff;
    -webkit-box-shadow: 0px 0px 25px 5px rgba(224, 224, 224, 0.527); 
    box-shadow: 0px 0px 25px 5px rgba(224, 224, 224, 0.527);
}

.navbar-dashboard__left {
    margin: auto 0;
}

.navbar-dashboard__img-container {
    margin: auto 0;
    padding-left: 1.5rem;
}

.navbar-dashboard__right {
    display: flex;
}

.navbar-dashboard__user {
    text-align: end;
    margin: auto 0;
    padding: 0;
    color: #fff;
}
.navbar-dashboard__user_2 {
    text-align: end;
    margin: auto 0;
    padding: 0;
    color: #002860;
}

.navbar-dashboard__username {
    font-weight: bold;
    margin-bottom: 0.1rem;
}

.navbar-dashboard__rol {
    margin-bottom: 0;
    font-size: 0.9rem;
}

.navbar-dashboard__profile-img {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    margin-right: 0.7rem;
}
#logo-header-incio{
    float: left;
    padding-left: 15px;
    padding-top: 5px;
    font-size: 13px;
    text-transform: uppercase;
}
@media(min-width:700px){
    .navbar-dashboard__toggle {
        display: none;
    }
    
}
@media (max-width: 768px) {
    #logo-header-incio{
        display: none;
    }
    #nombre_usuario{
        display: none;
    }
}