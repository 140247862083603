.dropdown__button {
    margin: 0;
    padding: 0;
}

.dropdown__button:active {
    background-color: #c5c5c5;
    color: #fff;
}
.navbar-dashboard__profile-img{
    border: 1px solid #002860;
}
.color .dropdown-toggle::after {
    color: #002860;
}
