.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, .5);
    backdrop-filter: blur(3px);
}

.modal__container::-webkit-scrollbar{
    margin-bottom: -5% !important;
    width: 1px;
    height: 8px;
    background: transparent;
}

.modal__container::-webkit-scrollbar-thumb{
    background: rgba(201, 194, 243, 0.856);
    border-radius: 4px;
}

.modal__container {
    width: auto;
    max-height: 90%;
    position: relative;
    background-color: #fff;
    overflow-y: scroll;
    scrollbar-width: 2px;
    scrollbar-color: rgba(222, 222, 250, 0.788) transparent;
    border-radius: 1rem;
}

.modal__content {
    padding: 3rem;
}

.modal_animated {
    animation: modalOpen ease-out .24s;
}

.modal__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
}

.Modal > div > section:nth-child(1) > button {
    border: none;
    width: 4rem;
    height: 4rem;
    border-radius: 4px;
    background-color: var(--color-light-grey);
    font-weight: 700;
    margin-bottom: 1rem;
    cursor: pointer;
}

@keyframes modalOpen {
    0% {
        transform: translate(0, 100%);
    }
    100% {
        transform: translate(0, 0);
    }
}

@media (min-width: 768px) {
    .modal__container {
        width: 45%;
    }
}

@media (min-width: 1440px) {
    .modal__container{
        width: 35%;
    }
}

