.applicant-list__main {
    padding: 0 1rem;
}

.applicant__navbar {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
}

.applicant-list__btn-download {
    font-size: 1.5rem;
    padding: 0.4rem;
    margin-right: 0.4rem;
    border-radius: 0.4rem;
}

.applicant-list__btn-download:hover {
    background-color: #eeeeee;
}

.nav-applicant-list {
    width: 85%;
    margin: 0 auto;
    background-color: #eeeeee;
    border-radius: 0.4rem;
}

.nav-applicant-list__ul {
    display: flex;
    list-style: none;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
}

.nav-applicant-list__item {
    padding: 0.5rem 0;
}

.nav-applicant-list__item > h5 {
    margin-bottom: 0;
}

.no-data {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.no-data__icon {
    width: 12rem;
    height: auto;
}

.no-data__text-main {
    color: #4e5859;
    font-size: 1.5rem;
    margin-top: 1rem;
}

.no-data__text-secondary {
    color: #6f7c7c;
}

.applicant-list__manipulate-list {
    display: flex;
    justify-content: space-between;
}

.applicant-list__button-add-applicant {
    display: flex;
    justify-content: flex-end;
}

@media (min-width: 768px) {
    .applicant-list__main {
        padding: 0 2rem;
    }
}