.button {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: .375rem 1.5rem;
    font-size: 0.9rem;
    line-height: 1.5;
    border-radius: .25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    margin: 0.5rem;
}

@media (min-width: 768px) {
    .button {
        font-size: 1rem
    }
}

.hover-button {
    background-color: #700909;
    border-color: #a70404;
}

.button.link {
    font-weight: 600;
}

.button.link:hover {
    text-decoration: underline;
}

.button.link-primary
.button.loading-progress {
    font-weight: 600;
    margin: 0;
    padding: 0.5rem;
    color: #005AA6;
}

.button.link-primary:hover 
.button.loading-progress:hover {
    color: #007fa4;
}

.button.back {
    background-color: #5a6268;
    color: #fff;
}

.button.back:hover {
    background-color: #6c757d;
}

.button.send {
    background-color: #005AA6;
    color: #fff;
}

.button.send:hover {
    background-color: #006eb7;
}

.button.new {
    background-color: #00833f;
    color: #fff;
}

.button.new:hover {
    background-color: #44ac34;
    color: #fff;
}

.button.danger {
    background-color: #b22029;
    color: #fff;
}

.button.danger:hover {
    background-color: #ca3725;
    color: #fff;
}

.button.blue {
    background-color: #005aa6;
    color: #fff;
}

.button.blue:hover {
    background-color: #00a3cc;
    color: #fff;
}

.button.blue-dark {
    padding: 0.2rem 1.5rem;
    background-color: #1d3660;
    color: #fff;
}

.button.blue-dark:hover {
    background-color: #01498e;
    color: #fff;
}

.button.light {
    background-color: #f5f5f5;
    color: #01498e;
}

.button.light:hover {
    background-color: #e4e4e4;
}

.button.light-big {
    padding: 0.5rem 2rem;
    font-weight: 600;
    font-size: 1.2rem;
    background-color: #f0f3f5;
    color: #002c5f;
    border-radius: 0.4rem;
}

.button.light-big:hover {
    -webkit-box-shadow: 0px 0px 0px 5px rgba(255, 255, 255, 0.452); 
    box-shadow: 0px 0px 0px 5px rgba(255, 255, 255, 0.452);
}

.button.blue-dark-big {
    padding: 0.5rem 2rem;
    font-weight: 600;
    font-size: 1.2rem;
    background-color: #013E7A;
    color: #fff;
    border-radius: 0.4rem;
}

.button.blue-dark-big:hover {
    background-color: #005aa6;
    color: #fff;
}

.button.outline-primary {
    background-color: transparent;
    border-color: #007bff;
    color: #007bff;
}

.button.outline-primary:hover {
    -webkit-box-shadow: 0px 0px 0px 3px rgba(0, 91, 166, 0.253); 
    box-shadow: 0px 0px 0px 3px rgba(0, 91, 166, 0.253);  
     
}

.button.outline-gray {
    background-color: transparent;
    border-color: #4e5859;
    color: #4e5859;
}

.button.outline-gray:hover {
    -webkit-box-shadow: 0px 0px 0px 3px rgba(140,143,144,0.59); 
    box-shadow: 0px 0px 0px 3px rgba(140,143,144,0.59);
}

.button.outline-white {
    background-color: transparent;
    border-color: #fff;
    color: #fff;
}

.button.outline-white:hover {
    -webkit-box-shadow: 0px 0px 0px 3px rgba(255,255,255,0.59); 
    box-shadow: 0px 0px 0px 3px rgba(255,255,255,0.59);
}

.button.outline-white-big {
    background-color: transparent;
    border-color: #fff;
    padding: 0.5rem 2rem;
    font-weight: 600;
    font-size: 1.2rem;
    border-radius: 0.4rem;
    color: #fff;
}

.button.outline-white-big:hover {
    -webkit-box-shadow: 0px 0px 0px 3px rgba(255, 255, 255, 0.59); 
    box-shadow: 0px 0px 0px 3px rgba(255, 255, 255, 0.59);
}

.button.circle {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #9eabaa48;
    padding: 1rem;
    width: 3rem;
    height: 3rem;
    border-radius: 4rem;
}

.button.circle:hover {
    background-color: #9eabaa81;
}

.button.loading-progress > img {
    width: 1.7rem;
    margin-left: 0.5rem;
}