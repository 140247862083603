/* .box-menu{
    box-shadow: rgb(0, 0, 0,.5) 5px 5px 7px;
    animation: fadeIn .1s ease-in-out;
}

@keyframes fadeIn {
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}
.box-element{
    transition: ease-in .12s;
}
.box-element-button{
    transition: ease-in .1s;
}
.cell{
    position: relative;
    transition: ease-in .05s;
}
.cell::after{
    content: "";
    inset: 0 98%;
    transition: ease-in .5s;
    position: absolute;
}
/* .cell:hover{
    box-shadow: rgb(0, 0, 0,.5) 5px 5px 7px;
    background-color: #1d356065;
}
.cell:hover *{
    font-weight: bold;
} */
.cell *{
    transition: ease-in .05s;
}
/* .cell *:hover{
    color: white !important;
    background-color: #ff00006e;
}
.box-element:hover{
    background-color: var(--bg-ocean);
    box-shadow: rgb(0, 0, 0,.5) 0px 1px 7px !important;
}
.box-element-button:hover{
    background-color: var(--bg-ocean);
    box-shadow: rgb(0, 0, 0,.5) 0px 1px 7px !important;
} */

/* table::-webkit-scrollbar {
    width: 5px;
    transition: ease-out .1s;
  }
  
  table::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  table::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  table::-webkit-scrollbar-thumb:hover {
    background: #00a3cc;
  }

.table-scrollbar {
    position: relative;
    height: 70vh;
    overflow: auto;
}
.table-wrapper-scroll-y {
}
.thead{
    position: sticky;
    top: 0;
}
tr{
    border-style: none !important;
} */
