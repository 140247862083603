.menu-list__container-start {
    position: sticky;
    top: 0;
    z-index: 1;
    box-shadow: rgba(24, 24, 24, 0.123) 0px 2px 5px 2px;
}

.menu-list__start-button {
    color: #4e5859;
    width: 100%;
    background-color: #f1f1f1;
    padding: 0.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.1s ease-in;
    margin-bottom: 0.2rem;
}

.menu-list__start-button:hover {
    background-color: #005AA6;
    color: #fff;
}

.menu-list__collapse-body {
    padding: 1rem 0rem;
    border-radius: 0.4rem;
    margin: 1rem;
    background-color: #9eabaa25;
}
.menu-list__button-modulo:hover {
    background-color: #9eabaa27;
    border-radius: 0.4rem;
    color: #fff;
    
}

.accordion-button:not(.collapsed) {
    background-color: #9eabaa27;
}

.menu-list__text-modulo {
    color: #4e5859;
    font-weight: 600;
}

.menu-list__collapse-item {
    display: list-item;
    padding: 0.7rem 1.2rem;
    color: #4e5859;
}

.menu-list__collapse-item:hover {
    background-color: #9eabaa27;
    color: #4e5859;
}

.menu-list__collapse-item--active {
    color: #006eb7 !important;
    font-weight: 500;
}
.icono img{
    padding: 5px;
    width: 30px;
    margin-right: 5px;
}
.menu-list__button-modulo{
    padding-left: 5px;
}
.accordion-button:after{
    opacity: 0.3;
}
