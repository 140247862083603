.admission__button {
    display: flex;
    justify-content: flex-end;
}

.admission__phase {
    border-radius: 0.4rem;
    border: 1px solid #c0c0c0;
    margin: 1.5rem 0;
}

.admission__phase-title-container {
    border-radius: 0.4rem;
    background-color: #dbdbdb;
    text-align: center;
    padding: 0.2rem;
}

.admission__phase-title-container > h5 {
    margin-bottom: 0;
    padding: 0.2rem 0;
    color: #4e5859;
    font-weight: 600;
}

.admission__phase-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
}

.admission__phase-info {
    width: 100%;
    margin-bottom: 1rem;
}

.admission__phase-info > h5 {
    margin: 1rem 0 1.5rem;
}

.admission__phase-info-text {
    color: #7a7a7a;
}

.admission__phase-info-text > span {
    font-weight: 600;
    color: #4e5859;
    padding: 0 0.4rem;
}

.admission__phase-info-text > p {
    margin-bottom: 0.2rem;
}

.admission__phase-info-text > p {
    margin-bottom: 0.2rem;
    font-size: 0.9rem;
    color: #4e5859;
}

.admission__button {
    display: flex;
    justify-content: flex-end;
}

.admission__phase-form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.admission__qualify {
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media (min-width: 768px) {

    .admission__phase-form {
        flex-direction: row;
        align-items: flex-end;
    }

    .admission__qualify {
        flex-direction: row;
        align-items: flex-end;
    }
}