.nav-tag__li {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.nav-tag__steps-progress-bar {
    display: flex;
    align-items: center;
}

.nav-tag__node {
    border-radius: 50%;
    width: 1rem;
    height: 1rem;
    z-index: 1;
}

.nav-tag__completed {
    background-color: #009945;
}

.nav-tag__not-completed {
    background-color: #eeeeee;
    border: solid 1px #009945;
}

.nav-tag__edge {
    background-color: #009945;
    width: 8rem;
    height: 0.1rem;
    position: absolute;
}

.nav-tag__ul-window {
    display: flex;
    justify-content: space-evenly;
    list-style: none;
    background-color: #eeeeee;
    margin: 0 auto;
    width: 23rem;
    border-radius: 0.4rem;
    padding: 0.5rem;
}

.nav-tag__nav-window-item {
    padding: 0;
    font-weight: 600;
    margin: 0;
    cursor: pointer;
    color: #52594e;
}

.nav-tag__nav-window-item:hover {
    font-weight: 600;
    color: #007fa4;
}

.nav-tag__nav-window-item--active {
    font-weight: 600;
    color: #007fa4;
}

@media (min-width: 768px) {
    .nav-tag__ul-window {
        border-radius: 0.4rem;
        padding-left: 0;
    }
}