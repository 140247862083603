.headerHome {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  top: 0;
  position: sticky;
}

.headerHome__logo {
  display: flex;
  padding-left: 1rem;
  margin-top: 10px;
}
#logo-header-incio{
    float: left;
    padding-left: 15px;
    padding-top: 5px;
    font-size: 13px;
    text-transform: uppercase;
}


.headerHome__toggle {
  font-size: 2rem;
}

.headerHome__signin {
  display: none;
}

@media(max-width:700){
  .headerHome {
    display: flex;
    /*  align-items: center; */
  }
}
@media (min-width: 768px) {
  .headerHome__toggle {
    display: none;
  }
  .headerHome__signin {
    display: block;
    margin-right: 1.4rem;
    width: 17rem;
  }

}

@media (min-width: 1440px) {

  .headerHome {
    display: flex;
    /* align-items: center; */
  }

  .headerHome__toggle {
    display: none;
  }

  .headerHome__signin {
    font-size: 1.2rem !important;
    margin-right: 2.2rem;
  }

}

.link_portafolio {
  text-decoration: none;
  font-size: 1.2rem;
  font-weight: 600;
  margin-left: 35vw;
}