.badge {
    display: flex;
    margin: 1rem;
    align-items: center;
    white-space: normal;
}

.badge__container {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin: 0;
    padding: 0;
}

.badge-list__img-container {
    margin-bottom: 0;
    margin-right: 1rem;
}

.badge-list__avatar {
    display: flex;
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    object-fit: cover;
}

.badge-list__award {
    display: flex;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    object-fit: cover;
    position: relative;
    margin-left: 2.5rem;
    margin-top: -1.2rem;
}

.badge-list__username {
    color: #4e5859;
    text-align: left;
}

.badge-list__id {
    color: #6f7c7c;
    font-weight: 400;
    text-align: start;
}