.message {
   padding: 0.5rem 1rem;
   font-weight: 700;
   border-radius: 0.5rem;
   margin: 0.4rem;
   text-align: center;
}

.message.error {
   color: hsl(356, 70%, 41%);
   background-color: #ca38252c;
}

.message.error-white {
   color: hsl(356, 70%, 41%);
   background-color: #fff;
}

.message.warning {
   color: #fff;
   background-color: #E9511D;
}

.message.warning-white {
   color: #E9511D;
   background-color: #fff;
}

.message.ok {
   color: #009945;
   background-color: #44ac3434;
}

.message.ok-white {
   color: #009945;
   background-color: #fff;
}