.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    flex-direction: column;
    justify-content: space-between;
    width: 20rem;
    background-color: rgba(0, 0, 0, 0.41);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 4;
}

.sidebar__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    position: relative;
}

.sidebar__button {
    width: 100%;
    position: absolute;
    top: 2%; 
    left: 5%;
}

.sidebar__icon {
    color: #fff;
    font-size: 2rem;
}

@media (min-width: 300px) {
    
.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.61);
    backdrop-filter: blur(3px);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 4;
    color: #fff;
}

.sidebar__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}

.sidebar__icon {
    color: #fff;
    font-size: 2rem;
}
}