.profile-header__main {
    padding: 0 2rem;
}

.profile-header__list-course {
    display: flex;
    flex-wrap: wrap;
}

.profile-header__card-course {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fafafa;
    border-radius: 0.5rem;
    padding: 0.5rem;
    width: 14rem;
    margin: 0.5rem;
}

.profile-header__course-content {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    margin-top: 0.5rem;
}