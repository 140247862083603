.offer {
    background-color: #f5f5f5;
    overflow: scroll;
}

.offer__container {
    margin: 0 1rem;
}

.offer__head {
    display: flex;
    align-items: center;
    padding: 2rem 0;
}

.offer__img-container {
    width: 25rem;
    height: 15rem;
    margin-right: 2rem;
}

.offer__img {
    width: 100%;
    height: 100%;
    border-radius: 0.4rem;
    object-fit: cover;
}

.offer__text-active {
    margin: 0 auto;
    margin-left: 1rem;
    color: #44AC34;
    font-size: 0.9rem;
}

.offer__info {
    padding: 0 2.5rem;
    display: grid;
    grid-template-columns: auto auto auto;
}

.offer__course {
    background-color: #F8F8F8;
    border-radius: 0.4rem;
    padding: 1.5rem 4rem;
    margin: 1rem;
}

@media (min-width: 768px) {
    .offer__container {
        width: 90%;
        margin: 0 auto;
    }

    .offer__description {
        display: flex;
        align-items: center;
    }
}

@media (min-width: 1024px) {
    .offer__container {
        width: 80%;
    }
}