.user-profile__head {
    /* display: flex; */
    flex-direction: column;
    align-items: center;
    padding: 2rem;
}

.user-profile__img-container {
    width: 10rem;
    height: 10rem;
}

.user-profile__img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
}

.user-profile__edit-data {
    padding: 2rem;
}

.user-profile__form {
    display: grid;
    grid-template-columns: auto auto;
}

.user-profile__button {
    width: 100%;
}

@media (min-width: 768px) {
    .user-profile__container {
        /* display: flex; */
        justify-content: center;
    }
}
.perfil{
    max-width: 800px;
    margin: 0 auto;
    min-height: 450px;
}