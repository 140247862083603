.estudiante-documento {
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.estudiante-documento__document {
    width: 100%;
    padding: 1rem;
    margin: 1rem;
    border: solid 1px #d8d8d8;
    border-radius: 0.4rem;
}

.estudiante-documento__document-title {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 1.5rem;
}

.estudiante-documento__update-document {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 1rem;
}