.documents__container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.documents__item-document {
    display: flex;
    width: 14rem;
    height: 14rem;
    margin: 1.5rem;
    border: 1px solid #dfdfdf;
    border-radius: 0.4rem;
    flex-direction: column;
    justify-content: space-around;
}

.documents__item-document:hover {
    border: none;
    background-color: #f5f5f5;
}

.documents__button-view-pdf {
    width: 100%;
    margin: 0;
    padding: 0;
    color: #4e5859;
}

.documents__button-view-pdf:hover {
    color: #4e5859;
}

.documents__info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.documents__date-container {
    text-align: justify;
}

.documents__date-title {
    font-size: 0.9rem;
    margin-bottom: 0;
}

.documents__date {
    font-size: 0.8rem;
    margin-bottom: 0;
}

.documents__control {
    width: 100%;
    padding: 0.2rem;
}

.documents__control-buttons-container {
    display: flex;
    justify-content: center;
    align-self: flex-end;
}

.documents__button-decline {
    padding: 0 0.5rem;
    margin: 0;
    color: #b22029;
    font-weight: 500;
}

.documents__button-decline:hover {
    color: #ca3725;
}

.documents__button-accept {
    padding: 0 0.5rem;
    margin: 0;
    font-weight: 500;
    color: #00594e;
}

.documents__button-accept:hover {
    color: #5e7361;
}

.documents__rejected-status {
    border-radius: 0.4rem;
    padding: 0.4rem;
    color: #fff;
    background-color: #ca3825c5;
}

.documents__accepted-status {
    border-radius: 0.4rem;
    padding: 0.4rem;
    color: #fff;
    background-color: #00994594;
}

.documents__button-enroll {
    display: flex;
    justify-content: center;
}

.documents_modal-accept {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
}

@media (min-width: 768px) {
    .documents__button-enroll {
        justify-content: flex-end;
    }
}