.card-pay{
  border-radius: 1rem;
  width: 20rem;
  display: flex;
  flex-direction: column;
  margin: 1rem;
  padding: 1rem;
  height: 24rem;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.card-pay__header-img{
  width: 20rem;
  height: 5rem;
  margin-bottom: 1rem;
}
.card-pay__body{
  display: flex;
  flex-direction: column;
 justify-content: center;
 align-items: center;
 position: relative;
 padding-bottom: 2rem;
}
.card-pay__body span{
  color: gray;
  font-size: 12px;
  height: 7rem;
  text-align: justify;
  margin-top: 1rem;
}
.card-pay__body__button{
  position: absolute;
margin-top: 19rem;
}
