.CardContainer{
   display: flex;
   justify-content: center;
   padding-top: 2rem;
}
.noticiaCard__img{
    width: 50%;
}
.cardContainer_1{
    border: 2px solid rgb(176, 176, 176);
    width: min(95%, 1000px);
    border-radius: 20px;
}

.img_noticia{
    width: min(50%, 600px);
    border-radius: 20px;
}

.titulo_noticia{
    font-size: 1.5rem;
    font-weight: bold;
    margin-top: 2rem;
    text-align: center;
}

.texto_noticia{
    font-size: 1.2rem;
}

.contenedor_texto_noticia{
    padding: 0.5rem;
    width: min(95%, 800px);
}