.show-result__content-result {
  display: flex;
  width: 100%;
  padding: 2rem 0;
  justify-content: center;
  align-items: center;
  background-color: rgb(255, 255, 255);
}

.show-result__result {
  width: 70%;
  border-radius: 0.4rem;
  border: #dddddd solid 1px;
  color: #202020;
  -webkit-box-shadow: 0px 0px 7px 1px rgba(192,192,192,0.35); 
  box-shadow: 0px 0px 7px 1px rgba(192,192,192,0.35);
}

.show-result__warning {
  width: 100%;
  border-radius: 0.4rem 0.4rem 0 0;
  padding: 0.8rem;
  font-weight: 700;
}

.show-result__warning--not-found {
  background-color: #ca382557;
  color: #592d2c;
}

.show-result__warning--found {
  background-color: #6fb74d59;
  color: #00833f;
}

.show-result__button-add-student {
    display: flex;
    justify-content: center;
}